import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { Box, Card, Container, CssBaseline, Grid, styled } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import Logo from 'src/components/Logo';
import BTLoginImg from '../../assets/erp.png';

interface BaseLayoutProps {
  children?: ReactNode;
}

const AuthWrapper = styled(Box)(
  () => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    align-items: center;
`
);

const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  return (
    <>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={3}
          md={5}
          sx={{
            backgroundImage: `url(${BTLoginImg})`, //"url(https://source.unsplash.com/random)",
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        />

        <AuthWrapper>
          <Helmet>
            <title>BlueTech Garments Management</title>
          </Helmet>
          <Container maxWidth="lg">
            <Box
              display="flex"
              justifyContent="center"
              py={5}
              alignItems="center"
            >
              <Logo />
            </Box>
            <Card sx={{ p: 1, mb: 1, borderRadius: 4 }}>
              {children || <Outlet />}
            </Card>
          </Container>
        </AuthWrapper>
      </Grid>
    </>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node
};

export default BaseLayout;
