import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { WebcamCapture } from './screens/pages/Transaction/STPurchaseEntry/cam';

const Loader = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const AdminCompany = Loader(
  lazy(() => import('src/screens/pages/Admin/Company'))
);
const AdminUsers = Loader(lazy(() => import('src/screens/pages/Admin/Users')));
const AdminCountry = Loader(
  lazy(() => import('src/screens/pages/Admin/Country'))
);
const AdminState = Loader(lazy(() => import('src/screens/pages/Admin/State')));
const AdminModes = Loader(lazy(() => import('src/screens/pages/Admin/Mode')));
const AdminTaxGroup = Loader(
  lazy(() => import('src/screens/pages/Admin/TaxGroup'))
);
const AdminTax = Loader(lazy(() => import('src/screens/pages/Admin/Tax')));
const AdminUom = Loader(lazy(() => import('src/screens/pages/Admin/Uom')));

const MastersSupplier = Loader(
  lazy(() => import('src/screens/pages/Masters/Supplier'))
);
const MastersEmployee = Loader(
  lazy(() => import('src/screens/pages/Masters/Employee'))
);
const MastersPOTax = Loader(
  lazy(() => import('src/screens/pages/Masters/POTax'))
);
const MastersPOGroup = Loader(
  lazy(() => import('src/screens/pages/Masters/POGroup'))
);
const MastersPOProduct = Loader(
  lazy(() => import('src/screens/pages/Masters/POProduct'))
);

const MTMJobType = Loader(
  lazy(() => import('src/screens/pages/Masters/JobType'))
);
const MTMItemType = Loader(
  lazy(() => import('src/screens/pages/Masters/ItemType'))
);

const AddrBook = Loader(
  lazy(() => import('src/screens/pages/Masters/AddrBook'))
);

const STIssueEntry = Loader(
  lazy(() => import('src/screens/pages/Transaction/STIssueEntry'))
);

const STPurchaseEntry = Loader(
  lazy(() => import('src/screens/pages/Transaction/STPurchaseEntry'))
);

const JobEntry = Loader(
  lazy(() => import('src/screens/pages/Transaction/JobEntry'))
);
const JobAssignEntry = Loader(
  lazy(() => import('src/screens/pages/Transaction/JobAssignEntry'))
);
const JobWorkEntry = Loader(
  lazy(() => import('src/screens/pages/Transaction/JobWorkEntry'))
);

// Pages

const Auth = Loader(lazy(() => import('src/screens/auth')));

// Dashboards
const Crypto = Loader(lazy(() => import('src/screens/dashboards/Crypto')));

// Status
const Status404 = Loader(
  lazy(() => import('src/screens/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/screens/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/screens/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/screens/pages/Status/Maintenance'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Crypto />
      }
    ]
  },
  {
    path: 'dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: 'crypto',
        element: <Crypto />
      }
    ]
  },
  {
    path: 'admin',
    element: <SidebarLayout />,
    children: [
      {
        path: 'company',
        element: <AdminCompany />
      },
      {
        path: 'users',
        element: <AdminUsers />
      },
      {
        path: 'country',
        element: <AdminCountry />
      },
      {
        path: 'state',
        element: <AdminState />
      },
      {
        path: 'mode',
        element: <AdminModes />
      },
      {
        path: 'taxgroup',
        element: <AdminTaxGroup />
      },
      {
        path: 'tax',
        element: <AdminTax />
      },
      {
        path: 'uom',
        element: <AdminUom />
      }
    ]
  },
  {
    path: 'masters',
    element: <SidebarLayout />,
    children: [
      {
        path: 'supplier',
        element: <MastersSupplier />
      },
      {
        path: 'employee',
        element: <MastersEmployee />
      },
      {
        path: 'jobtype',
        element: <MTMJobType />
      },
      {
        path: 'itemtype',
        element: <MTMItemType />
      },
      {
        path: 'potax',
        element: <MastersPOTax />
      },
      {
        path: 'pogroup',
        element: <MastersPOGroup />
      },
      {
        path: 'poproduct',
        element: <MastersPOProduct />
      }
    ]
  },
  {
    path: 'entry',
    element: <SidebarLayout />,
    children: [
      {
        path: 'addrbook',
        element: <AddrBook />
      },
      {
        path: 'purchaseentry',
        element: <STPurchaseEntry />
      },
      {
        path: 'webcam',
        element: <WebcamCapture />
      },
      {
        path: 'issueentry',
        element: <STIssueEntry />
      },
      {
        path: 'jobentry',
        element: <JobEntry />
      },
      {
        path: 'jobassign',
        element: <JobAssignEntry />
      },
      {
        path: 'jobwork',
        element: <JobWorkEntry />
      }
    ]
  },
  {
    path: '*',
    element: <Auth />
  }
];

const authroutes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Auth />
      },
      {
        path: 'company',
        element: <AdminCompany />
      }
    ]
  },
  {
    path: '*',
    element: <Auth />
  }
];

export default routes;
export { authroutes };
