import { createSlice } from "@reduxjs/toolkit";
import { usersApi } from "./ausersApi";
import type { RootState } from "../store";

export interface AuthState {
	user: string;
	username: string;
	token: string;
	company: string;
	companyname: string;
	addr1: string;
	addr2: string;
	city: string;
	state: string;
	pincode: string;
	phone: string;
	email: string;
	web: string;
}

const initialState: AuthState = {
	user: "",
	username: "",
	token: "",
	company: "",
	companyname: "",
	addr1: "",
	addr2: "",
	city: "",
	state: "",
	pincode: "",
	phone: "",
	email: "",
	web: "",
};

export const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		resetState() {
			return initialState;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			usersApi.endpoints.signIn.matchFulfilled,
			(state, { payload }) => {
				state.user = payload.us_id;
				state.username = payload.us_name;
				state.token = payload.us_token;
				state.company = payload.cm_id;
				state.companyname = payload.cm_name;
				state.addr1 = payload.cm_addr1;
				state.addr2 = payload.cm_addr2;
				state.city = payload.cm_city;
				state.state = payload.cm_state;
				state.pincode = payload.cm_pincode;
				state.phone = payload.cm_phone;
				state.email = payload.cm_email;
				state.web = payload.cm_web;
			}
		);
	},
});

export default authSlice.reducer;
export const { resetState } = authSlice.actions;

export const selectCurrentUser = (state: RootState) => state.gdata;
