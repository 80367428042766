import {
  combineReducers,
  configureStore,
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';

import { companyApi } from '../services/acompanyApi';
import { countryApi } from '../services/acountryApi';
import { stateApi } from '../services/astateApi';
import { usersApi } from '../services/ausersApi';
import { authSlice } from '../services/authSlice';

import { modeApi } from '../services/amodeApi';
import { taxgroupApi } from '../services/ataxgroupApi';
import { taxApi } from '../services/ataxApi';
import { uomApi } from '../services/auomApi';

import { emmemployeeApi } from '../services/emmemployeeApi';
import { addrbookApi } from '../services/addrbookApi';

import { mtmjobtypeApi } from '../services/mtmjobtypeApi';
import { mtmitemtypeApi } from '../services/mtmitemtypeApi';
import { mtejobApi } from '../services/mtejobApi';
import { mtejobassignApi } from '../services/mtejobassignApi';
import { mtejobworkApi } from '../services/mtejobworkApi';

import { pomtaxApi } from '../services/pomtaxApi';
import { pomgroupApi } from '../services/pomgroupApi';
import { pomproductApi } from '../services/pomproductApi';

import { stsettingApi } from '../services/stasettingApi';
import { stmsupplierApi } from '../services/stmsupplierApi';
import { stepurchaseApi } from '../services/stepurchaseApi';
import { steissueApi } from '../services/steissueApi';

import notification from '../../components/Notification';
import { logicSlice } from '../services/agen';
import Logout from 'src/screens/auth/Logout';

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      switch (action.payload.originalStatus) {
        case 500: {
          notification.error({
            title: 'Server Error',
            message: action.payload.data
          });
          break;
        }
        case 400: {
          notification.error({
            title: 'Error',
            message: action.payload.data
          });
          break;
        }
        case 401: {
          notification.error({
            title: 'Error',
            message: action.payload.data
          });
          console.log('LogOut');
          Logout();
          break;
        }
        case 300: {
          notification.error({
            title: 'Error',
            message: action.payload.data
          });
          break;
        }
        case 200: {
          notification.success({
            title: 'Sucess',
            message: action.payload.data
          });
          break;
        }
      }
    }

    return next(action);
  };

const rootReducer = combineReducers({
  [usersApi.reducerPath]: usersApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [countryApi.reducerPath]: countryApi.reducer,
  [stateApi.reducerPath]: stateApi.reducer,
  [modeApi.reducerPath]: modeApi.reducer,
  [taxgroupApi.reducerPath]: taxgroupApi.reducer,
  [taxApi.reducerPath]: taxApi.reducer,
  [uomApi.reducerPath]: uomApi.reducer,
  [pomtaxApi.reducerPath]: pomtaxApi.reducer,
  [pomgroupApi.reducerPath]: pomgroupApi.reducer,
  [pomproductApi.reducerPath]: pomproductApi.reducer,
  [stsettingApi.reducerPath]: stsettingApi.reducer,
  [stmsupplierApi.reducerPath]: stmsupplierApi.reducer,
  [stepurchaseApi.reducerPath]: stepurchaseApi.reducer,
  [steissueApi.reducerPath]: steissueApi.reducer,
  [emmemployeeApi.reducerPath]: emmemployeeApi.reducer,
  [addrbookApi.reducerPath]: addrbookApi.reducer,
  [mtmjobtypeApi.reducerPath]: mtmjobtypeApi.reducer,
  [mtmitemtypeApi.reducerPath]: mtmitemtypeApi.reducer,
  [mtejobApi.reducerPath]: mtejobApi.reducer,
  [mtejobassignApi.reducerPath]: mtejobassignApi.reducer,
  [mtejobworkApi.reducerPath]: mtejobworkApi.reducer,

  gdata: authSlice.reducer,
  glogic: logicSlice.reducer
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddiware) =>
    getDefaultMiddiware().concat(
      rtkQueryErrorLogger,
      usersApi.middleware,
      companyApi.middleware,
      countryApi.middleware,
      stateApi.middleware,
      modeApi.middleware,
      taxgroupApi.middleware,
      taxApi.middleware,
      uomApi.middleware,
      pomtaxApi.middleware,
      pomgroupApi.middleware,
      pomproductApi.middleware,
      stsettingApi.middleware,
      stmsupplierApi.middleware,
      stepurchaseApi.middleware,
      steissueApi.middleware,
      emmemployeeApi.middleware,
      addrbookApi.middleware,
      mtmjobtypeApi.middleware,
      mtmitemtypeApi.middleware,
      mtejobApi.middleware,
      mtejobassignApi.middleware,
      mtejobworkApi.middleware
    ),
  devTools: true
});

export default store;
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
